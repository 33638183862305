import { axiosCore } from '@/plugins/axios';
import { errorAlert } from '@/ort-lib/utils/utils';

const state = {
  hasNextPage: true,
  isDocumentsLoading: true,
  documentNotifications: [],
  reportingFunds: [],
};

const getters = {
  getHasNextPage: (state) => state.hasNextPage,
  getisDocumentsLoading: (state) => state.isDocumentsLoading,
  getDocumentNotifications: (state) => state.documentNotifications,
  getReportingFunds: (state) => state.reportingFunds,
};

const actions = {
  async fetchSADocuments({ commit, dispatch, getters }, { currency, search = '' }) {
    try {
      commit('setisDocumentsLoading', true);
      const queryParams = `?investor=${getters.portfolioInvestorId}&currency=${currency}&search=${search}`;
      const response = await axiosCore.get(`/investors/sa-documents${queryParams}`);
      commit('setDocumentNotifications', response.data);
      dispatch('resetReportingFundNotifications');
    } catch (err) {
      errorAlert('Problem fetching legal docs, try again later', err);
    } finally {
      commit('setisDocumentsLoading', false);
    }
  },

  async fetchByLawsDocuments({ commit, dispatch, getters }, { currency, search = '' }) {
    try {
      commit('setisDocumentsLoading', true);
      const queryParams = `?investor=${getters.portfolioInvestorId}&currency=${currency}&search=${search}`;
      const response = await axiosCore.get(`/investors/by-laws-documents${queryParams}`);
      commit('setDocumentNotifications', response.data);
      dispatch('resetReportingFundNotifications');
    } catch (err) {
      errorAlert('Problem fetching legal docs, try again later', err);
    } finally {
      commit('setisDocumentsLoading', false);
    }
  },

  async fetchAccountsDocuments({ commit, dispatch, getters }, { currency, search = '' }) {
    try {
      commit('setisDocumentsLoading', true);
      const queryParams = `?investor=${getters.portfolioInvestorId}&currency=${currency}&search=${search}`;
      const response = await axiosCore.get(`/investors/accounts-documents${queryParams}`);
      commit('setDocumentNotifications', response.data);
      dispatch('resetReportingFundNotifications');
    } catch (err) {
      errorAlert('Problem fetching accounts docs, try again later', err);
    } finally {
      commit('setisDocumentsLoading', false);
    }
  },

  async fetchReportingNotifications({ commit, getters }, { investorId, currency, page = 1, search = '' }) {
    try {
      commit('setisDocumentsLoading', true);
      let queryParams = `?investor=${investorId || getters.portfolioInvestorId}&currency=${currency}`;
      if (search) {
        queryParams += `&search=${search}`;
      } else {
        queryParams += `&page=${page}`;
      }
      const response = await axiosCore.get(`/investor-reportings/${queryParams}`);
      commit('setDocumentNotifications', response.data.results);
      commit('setHasNextPage', !!response.data.next);
    } catch (err) {
      errorAlert('Problem fetching reports, try again later', err);
    } finally {
      commit('setisDocumentsLoading', false);
    }
  },

  async fetchReportingFundNotifications({ commit, getters }, { startupId, unread }) {
    try {
      const response = await axiosCore.get(
        `/investor-reportings/?investor=${getters.portfolioInvestorId}&startup=${startupId}&unread=${unread}`,
      );
      const data = response.data.results?.filter( // remove duplicates
        (item, pos, arr) => !pos || item.reporting.created != arr[pos - 1].reporting.created,
      );
      commit('setReportingFunds', data);
    } catch (err) {
      console.error(err)
    }
  },

  resetReportingFundNotifications({ commit }) {
    commit('setReportingFunds', []);
  },
};

const mutations = {
  setHasNextPage: (state, data) => state.hasNextPage = data,
  setisDocumentsLoading: (state, data) => state.isDocumentsLoading = data,
  setDocumentNotifications: (state, data) => state.documentNotifications = data,
  setReportingFunds: (state, data) => state.reportingFunds = data,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
