import { axiosCore } from '@/plugins/axios';

const PAGE_SIZE = 5;

const state = {
  partnerClients: {
    results: [],
    next: 1,
  },
  arePartnerClientsLoading: false,
  loadingInviteInvestor: false,
  requestInviteInvestorFinished: false,
  partnerClientDashboard: {},
  isPartnerClientDashboardLoading: false,
  partnerRequestedCall: false,
  isPartnerRequestCallLoading: false,
};

const getters = {
  getPartnerRequestedCall: (state) => state.partnerRequestedCall,
  getIsPartnerClientDashboardLoading: (state) => state.isPartnerClientDashboardLoading,
  getPartnerClientDashboard: (state) => state.partnerClientDashboard,
  getIsPartnerRequestCallLoading: (state) => state.isPartnerRequestCallLoading,
};

const actions = {
  async fetchPartnerClients(
    { commit, getters },
    {
      searchToken = '', offerType = '', isOrderInvestment = '', isOrderPortfolio = '',
    } = {},
  ) {
    commit('setArePartnerClientsLoading', true);
    try {
      const partnerId = getters.entityId;
      const response = await axiosCore.get(
        `partners/${partnerId}/clients?page=${state.partnerClients.next ?? 1}&page_size=${PAGE_SIZE}&search=${searchToken}&offer_type=${offerType}&order_investment=${isOrderInvestment}&order_portfolio=${isOrderPortfolio}`,
      );
      commit('setPartnerClients', response.data);
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('setArePartnerClientsLoading', false);
    }
  },
  async partnerInvitesInvestor({ commit, getters, dispatch }, data) {
    commit('changeloadingInviteInvestor', true);
    try {
      const partnerId = getters.entityId;
      const url = `/partners/${partnerId}/invite-investor`;
      await axiosCore.post(url, data);
      dispatch('successAlert', { message: 'Investor successfully invited!' }, { root: true });
    } catch (error) {
      throw new Error(error.message);
    } finally {
      commit('changeloadingInviteInvestor', false);
      commit('setRequestInviteInvestorFinished', true);
    }
  },
  async fetchPartnerClientDashboard({ commit, dispatch, getters }) {
    const partnerId = getters.entityId;
    const userId = getters.getUserId;
    const url = `/partners/${partnerId}/user/${userId}/client-dashboard-overview`;
    commit('setIsPartnerClientDashboardLoading', true);
    try {
      const response = await axiosCore.get(url);
      commit('setPartnerClientDashboard', response.data);
    } catch (error) {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to get client dashboard overview.',
      });
    } finally {
      commit('setIsPartnerClientDashboardLoading', false);
    }
  },
  async partnerRequestCall({ commit, dispatch, getters }) {
    const partnerId = getters.entityId;
    const url = `/partners/${partnerId}/request-call`;
    commit('setIsPartnerRequestCallLoading', true);
    try {
      const response = await axiosCore.get(url);
      commit('setPartnerRequestedCall', response.data);
      dispatch('successAlert', {
        message: 'Your call request has been register, our investor relationship team will contact you soon by email!',
      });
    } catch (error) {
      dispatch('dangerAlert', {
        message: 'Something went wrong trying to request a call with us.',
      });
    } finally {
      commit('setIsPartnerRequestCallLoading', false);
    }
  },
};

const mutations = {
  setArePartnerClientsLoading: (state, data) => {
    state.arePartnerClientsLoading = data;
  },
  setPartnerClients: (state, data) => {
    state.partnerClients = {
      ...data,
      results: [
        ...data.results,
      ],
    };
  },
  changeloadingInviteInvestor: (state, data) => { state.loadingInviteInvestor = data; },
  setRequestInviteInvestorFinished: (state, data) => { state.requestInviteInvestorFinished = data; },
  setIsPartnerClientDashboardLoading: (state, data) => { state.isPartnerClientDashboardLoading = data; },
  setPartnerClientDashboard: (state, data) => { state.partnerClientDashboard = data; },
  setPartnerRequestedCall: (state, data) => { state.partnerRequestedCall = data; },
  setIsPartnerRequestCallLoading: (state, data) => { state.isPartnerRequestCallLoading = data; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
