import Cookies from 'js-cookie';
import router from '@/router/index';
import JwtToken from '@/plugins/jwt';
import { axiosCoreNoToken } from '@/plugins/axios';
import { errorAlert } from '@/ort-lib/utils/utils';

/**
 * JSDoc is a markup language used to annotate JavaScript source code files.
 * It is used to add information about the code, eg what it does, what parameters it takes, what it returns.
 * JSDoc is a lighter-weight alternative to TypeScript and does not force you to use types.
 * We are adopting JSDoc in our heavily-used stores to make it easier to use in multiple components.
 * This lets us know what each store method/property takes in and returns.
 *
 * For more info on how we use JSDoc see this: https://www.notion.so/oneragtime/a56830e220b6466dac5d9892610bcb7e
 *
 * The official documentation of JSDoc can be found here: https://jsdoc.app/
*/

/** @typedef {import("@/ort-lib/types/accounts/jwt.js").Payload} Payload */
/** @typedef {import("@/ort-lib/types/accounts/jwt.js").TokenResponse} TokenResponse */

const frontend = import.meta.env.VITE_FRONTEND_NAME;
const accessCookie = `${frontend}_ort_jwt_access_token`;
const refreshCookie = `${frontend}_ort_jwt_refresh_token`;
const accessToken = Cookies.get(accessCookie) || '';
const refreshToken = Cookies.get(refreshCookie) || '';

/**
 * @typedef {{
 *  accesstoken: {
 *    token: string; // Access token string.
 *    payload: Payload; // Payload of the access token.
 *  };
 *  refreshtoken: {
 *    token: string; // Refresh token string.
 *    payload: Payload; // Payload of the refresh token.
 *  };
 * }} State
*/

/** @type {State} */
const state = {
  accesstoken: {
    token: accessToken,
    payload: accessToken ? new JwtToken(accessToken).decode() : null,
  },
  refreshtoken: {
    token: refreshToken,
    payload: refreshToken ? new JwtToken(refreshToken).decode() : null,
  },
};

const getters = {
  /** @param {State} state */
  accesstoken: (state) => state.accesstoken.token,
  /** @param {State} state */
  refreshtoken: (state) => state.refreshtoken.token,
  /** @param {State} state */
  accesstokenPayload: (state) => state.accesstoken.payload,
  /** @param {State} state */
  refreshtokenPayload: (state) => state.refreshtoken.payload,
  /** @param {State} state */
  isAuthenticated: (state) => {
    if (!state.refreshtoken.payload) return false;
    return !(new JwtToken(state.refreshtoken.token).is_expired());
  },
  /** @param {State} state */
  isActive: (state) => state.accesstoken.payload?.is_active,
  /** @param {State} state */
  isOnboarded: (state) => state.accesstoken.payload?.onboarded_on !== 'None',
  /** @param {State} state */
  language: (state) => state.accesstoken.payload?.language || 'EN',
  /** @param {State} state */
  betaStatus: (state) => state.accesstoken.payload?.beta_status || 'no_access',
  /** @param {State} state */
  hasBetaAccess: (state) => state.accesstoken.payload?.beta_status?.startsWith('beta_'),
  /** @param {State} state */
  betaEnabled: (state) => state.accesstoken.payload?.beta_status === 'beta_enabled',
  /** @param {State} state */
  getUserId: (state) => state.accesstoken.payload?.user_id,
  /** @param {State} state */
  onboardedOn: (state) => state.accesstoken.payload?.onboarded_on,
  /** @param {State} state */
  entityId: (state) => state.accesstoken.payload?.active_profile?.entity_id,
  /** @param {State} state */
  activeEntityType: (state) => state.accesstoken.payload?.active_profile?.entity_type,
  /** @param {State} state */
  activeRelationshipId: (state) => state.accesstoken.payload?.active_profile?.relationship_id,
  /** @param {State} state */
  isActiveInvestor: (state) => state.accesstoken.payload?.active_profile?.entity_type === 'investor',
  /** @param {State} state */
  isActiveCorporate: (state) => state.accesstoken.payload?.active_profile?.entity_type === 'corporate',
  /** @param {State} state */
  isActiveStartup: (state) => state.accesstoken.payload?.active_profile?.entity_type === 'startup',
  /** @param {State} state */
  isActivePartner: (state) => state.accesstoken.payload?.active_profile?.entity_type === 'partner',
  /** @param {State} state */
  isOrtStaff: (state) => state.accesstoken.payload?.group_list?.includes('OrtStaff'),
  /** @param {State} state */
  isOrtFinance: (state) => state.accesstoken.payload?.group_list?.includes('OrtFinance'),
  /** @param {State} state */
  getIsRefusedEntity: (state) => state.accesstoken.payload?.active_profile?.entity_status === 'refused',
  /** @param {State} state */
  getAccessToOnlyPortfolioCard: (state) => {
    const entityStatus = state.accesstoken.payload?.active_profile?.entity_status;
    return ['portfolio_only', 'dormant'].includes(entityStatus);
  },
};

const actions = {
  /**
   * Uses creds to obtain JWT tokens from the server and stores them in store's state and in browswer cookies.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{ email: string; password: string; }} data
  */
  async getToken({ commit }, data) {
    try {
      /** @type {{data: TokenResponse}} */
      const response = await axiosCoreNoToken.post('/auth/token/', data);
      commit('setAccessToken', response.data.access);
      commit('setRefreshToken', response.data.refresh);
      return response;
    } catch (error) {
      errorAlert('Failed to get token', error);
      return Promise.reject(error);
    }
  },
  /**
   * Request for new JWT tokens using the refresh token.
   * @param {Object} context - Implicit parameter for vuex actions.
  */
  async refreshTokens({ commit }) {
    const refresh_token = Cookies.get(refreshCookie);
    if (!refresh_token)
      return Promise.reject(new Error('No refresh token found'));
    /** @type {{data: TokenResponse}} */
    const response = await axiosCoreNoToken.post('/auth/token/refresh/', {
      refresh: refresh_token,
    });
    commit('setAccessToken', response.data.access);
    commit('setRefreshToken', response.data.refresh);
    return response;
  },
  /**
   * Request for updated JWT tokens using the refresh token. Useful if claims in payload have changed.
   * @param {Object} context - Implicit parameter for vuex actions.
  */
  async hardRefreshTokens({ commit, getters }) {
    if (!getters.refreshtoken)
      return Promise.reject(new Error('No refresh token found'));
    /** @type {{data: TokenResponse}} */
    const response = await axiosCoreNoToken.post('/auth/token/hard-refresh/', { refresh: getters.refreshtoken });
    commit('setAccessToken', response.data.access);
    commit('setRefreshToken', response.data.refresh);
    return response;
  },
  /**
   * Removes the JWT tokens from the store and redirects to the login page if redirection is true.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {boolean} [redirect=true] - Whether to redirect to the login page after removing the tokens.
  */
  removeTokens({ commit }, redirect = true) {
    commit('deleteTokens');
    if (redirect) router.push({ name: 'Login', query: { logout: true } });
  },
};

const mutations = {
  /**
   * Updates the store's state with provided JWT access token.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {string} accesstoken - The latest JWT access token.
  */
  setAccessToken: (state, accesstoken) => {
    Cookies.set(accessCookie, accesstoken, { expires: 30 });
    state.accesstoken = {
      token: accesstoken,
      payload: new JwtToken(accesstoken).decode(),
    };
  },
  /**
   * Updates the store's state with provided JWT refresh token.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {string} refreshtoken - The latest JWT refresh token.
  */
  setRefreshToken: (state, refreshtoken) => {
    Cookies.set(refreshCookie, refreshtoken, { expires: 30 });
    state.refreshtoken = {
      token: refreshtoken,
      payload: new JwtToken(refreshtoken).decode(),
    };
  },
  /**
   * Removes the JWT tokens from the store and browser cookies.
   * @param {State} state - Implicit parameter for vuex mutations.
  */
  deleteTokens: (state) => {
    Cookies.remove(accessCookie);
    Cookies.remove(refreshCookie);
    state.refreshtoken = { token: '', payload: null};
    state.accesstoken = { token: '', payload: null};
  },
};

/** @typedef {typeof getters} Getters */
/** @typedef {typeof actions} Actions */
/** @typedef {typeof mutations} Mutations */

/**
 * This module exports an object with state, getters, actions and mutations related to JWT.
 * @module jwt
 * @typedef {Object} Jwt
 * @property {State} state - The state object related to JWT.
 * @property {Getters} getters - The getters object related to JWT.
 * @property {Actions} actions - The actions object related to JWT.
 * @property {Mutations} mutations - The mutations object related to JWT.
*/

export default {
  state,
  getters,
  actions,
  mutations,
};
