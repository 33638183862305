<template>
  <button
    :class="[{
      'button': true,
      'button--loading': loading,
      'no-background': !hasBackground,
      'responsive-width': widthResponsive },
      `button-color--${color}`
    ]"
    :style="cssProps"
    :disabled="disabled"
    @mousedown="$emit('click')"
  >
    <span v-if="loading">
      <i class="fa fa-spinner fa-spin button__loading-icon"></i>
    </span>
    <slot v-else>
      <i v-if="leftIcon" :class="`fas fa-${leftIcon} icon icon-left icon--${color}`"></i>
      {{ buttonText }}
      <i v-if="icon" :class="`fas fa-${icon} icon icon--${color}`"></i>
    </slot>
  </button>
</template>

<script>

export default {
  name: 'Button',
  emits: ['click'],
  props: {
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary', 'tertiary', 'success', 'danger', 'white'].indexOf(value) !== -1;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
    },
    width: {
      type: String,
      default: '200px',
    },
    widthResponsive: {
      type: Boolean,
      default: false,
    },
    hasBackground: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: '',
    },
    leftIcon: {
      type: String,
      default: '',
    },
    padding: {
      type: String,
      default: '8px 16px',
    },
  },
  computed: {
    cssProps() {
      return {
        '--width': this.width,
        '--padding': this.padding,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  width: var(--width);
  border: none;
  padding: var(--padding);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  @extend .text-sm--bold;

  &--loading {
    cursor: wait;
    pointer-events: none;
  }
}
// Button Primary
.button-color--primary {
  background-color: $primary;
  color: $white;
  &:hover,
  &:active {
    background-color: $cobalt-100;
    outline: none;
    border: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: default !important;
    background-color: $cobalt-300;
    outline: none;
    border: none;
  }
}
// Button Secondary
.button-color--secondary {
  color: $primary;
  background-color: $cobalt-600;
  &:hover,
  &:active {
    background-color: $cobalt-400;
    outline: none;
    border: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: default !important;
    background-color: $cobalt-600;
    color: $cobalt-300;
    outline: none;
    border: none;
  }
}
// Button Tertiary
.button-color--tertiary {
  color: $primary;
  background-color: $white;
  &:hover,
  &:active {
    background-color: $cobalt-600;
    outline: none;
    border: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: default !important;
    pointer-events: none;
    background-color: $white;
    color: $cobalt-300;
    outline: none;
    border: none;
  }
}
// Button Success
.button-color--success {
  background-color: $green-500;
  color: $success;
  &:hover,
  &:active {
    outline: none;
    border: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: default !important;
    pointer-events: none;
    outline: none;
    border: none;
  }
}
// Button Danger
.button-color--danger {
  @extend .button-color--primary;
  background-color: $danger;
  &:hover,
  &:active {
    background-color: $secondary;
    outline: none;
    border: none;
    cursor: default;
  }
}
// Button Danger
.button-color--white {
  color: $primary;
  background-color: $white;
  &:hover,
  &:active {
    background-color: $cobalt-400;
    outline: none;
    border: none;
  }
}
// icon
.icon {
  font-weight: bold;
  padding: 0 8px;
  //background-color: $white;

  &-left {
    padding: 0 2px 0 0;
  }
  &--primary {
    color: $white;
  }
  &--secondary {
    color: $primary;
  }
  &--tertiary {
    color: $primary;
  }
  &--danger {
    color: $white;
  }
}
// Button without background
.no-background{
  background-color: transparent;
  color: rgba(132, 113, 176, 1);
  &:hover {
    background-color: transparent;
    outline: none;
    border: none;
  }
}

@media screen and (max-width: 715px) {
  .responsive-width {
    width: auto;
  }
}
</style>
