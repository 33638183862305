/**
 * JSDoc is a markup language used to annotate JavaScript source code files.
 * It is used to add information about the code, eg what it does, what parameters it takes, what it returns.
 * JSDoc is a lighter-weight alternative to TypeScript and does not force you to use types.
 * We are adopting JSDoc in our heavily-used stores to make it easier to use in multiple components.
 * This lets us know what each store method/property takes in and returns.
 *
 * 
 * For more info on how we use JSDoc see this: https://www.notion.so/oneragtime/a56830e220b6466dac5d9892610bcb7e
 *
 * The official documentation of JSDoc can be found here: https://jsdoc.app/
 */

/** @typedef {import("src/ort-lib/types/entities/startup.js").Links} Links */
/** @typedef {import("src/ort-lib/types/entities/startup.js").StartupData} StartupData */
/** @typedef {StartupData["kyc"] KYC} */
/** @typedef {StartupData["startup"] Startup} */
/** @typedef {StartupData["team_info"] StartupTeamInfo} */

import { axiosCore } from '@/plugins/axios';
import { isEmpty } from '@/ort-lib/utils/validators';
import { errorAlert, successAlert } from '@/ort-lib/utils/utils';

/**
 * @typedef {{
 *  forceFetchStartup: boolean;
 *  currentStartupId: number;
 *  startupData: Object.<number, StartupData>;
 * }} State
 */

/** @type {State} */
const state = {
  forceFetchStartup: false,
  currentStartupId: 0,
  startupData: {
    0: {
      startup: {},
      team_info: [],
      kyc: {},
    },
  },
};

const getters = {
  /** @param {State} state */
  getCurrentStartupId: (state) => state.currentStartupId,
  /** @param {State} state */
  getStartupData: (state) => state.startupData[state.currentStartupId],
  /** @param {State} state */
  getStartup: (state) => state.startupData[state.currentStartupId].startup,
  /** @param {State} state */
  startupTeam: (state) => state.startupData[state.currentStartupId].team_info,
  /** @param {State} state */
  startupKYC: (state) => state.startupData[state.currentStartupId].kyc,
};

const actions = {
  /**
   * Get information about the startup.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} startupId - The ID of the startup.
  */
  async getStartup({ commit }, startupId) {
    try {
      if (isEmpty(state.startupData[startupId]?.startup) || state.forceFetchStartup) {
        /** @type {{data: Startup}} */
        const response = await axiosCore.get(`startups/${startupId}/`);
        commit('setStartupData', { startupId, startupData: { startup: response.data } });
        commit('setForceFetchStartup', false);
      }
      commit('setCurrentStartupId', startupId);
      return state.startupData[startupId].startup;
    } catch (error) {
      errorAlert('The Startup could not be fetched!', error);
    };
  },
  /**
   * Update information about the startup.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  startupId: number;
   *  name?: string;
   *  tagline?: string;
   *  company_description?: string;
   *  started_on?: string;
   *  ended_on?: string;
   *  founding_date?: string;
   *  status?: string;
   *  video_url?: string;
   *  currency?: number;
   *  links?: Links;
   *  responded?: boolean;
   *  discarded?: boolean;
   * }} payload - The startup's data.
  */
  async updateStartup({ commit, dispatch }, { startupId, ...data }) {
    try {
      await axiosCore.patch(`/startups/${startupId}/`, data);
      successAlert('The Startup was successfully updated!');
      commit('setForceFetchStartup', true);
      await dispatch('getStartup', startupId);
      commit('setCurrentStartupId', startupId);
      return state.startupData[startupId].startup;
    } catch (error) {
      errorAlert('The Startup could not be updated!', error);
    };
  },
  /**
   * Delete Startup.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} startupId - The ID of the startup.
   * @returns {Promise<boolean>}
  */
  async deleteStartup({ commit }, startupId) {
    try {
      await axiosCore.delete(`/startups/${startupId}/delete`);
      return successAlert('The Startup was successfully deleted!');
    } catch (error) {
      return errorAlert('The Startup could not be deleted!', error);
    };
  },
  /**
   * Get the startup team.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} startupId - The ID of the startup.
  */
  async getStartupTeam({ commit }, startupId) {
    try {
      if (isEmpty(state.startupData[startupId]?.team_info) || state.forceFetchStartup) {
        /** @type {{data: StartupTeamInfo[]}} */
        const response = await axiosCore.get(`startups/${startupId}/team-info`);
        commit('setStartupData', { startupId, startupData: { team_info: response.data } });
        commit('setForceFetchStartup', false);
      }
      commit('setCurrentStartupId', startupId);
      return state.startupData[startupId].team_info;
    } catch (error) {
      errorAlert('The Startup Team could not be fetched!', error);
    };
  },
  /**
   * Update UserStartupRelationship for the startup.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  startupId: number;
   *  relId: number;
   *  position?: string;
   *  is_owner?: boolean;
   *  is_contact?: boolean;
   *  is_founder?: boolean;
   *  status?: string;
   * }} payload
  */
  async updateStartupMember({ commit, dispatch }, { startupId, relId, ...payload }) {
    try {
      await axiosCore.patch(`/relationships/startup/${relId}/`, payload);
      successAlert('Member updated successfully!');
      commit('setForceFetchStartup', true);
      await dispatch('getStartupTeam', startupId);
      commit('setCurrentStartupId', startupId);
      return state.startupData[startupId].team_info;
    } catch (error) {
      errorAlert('Failed to update member!', error);
    };
  },
  /**
   * Delete UserStartupRelationship for the startup.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {{
   *  startupId: number;
   *  relId: number;
   * }} payload
   * @returns {Promise<boolean>}
  */
  async deleteStartupMember({ commit, dispatch }, { startupId, relId }) {
    try {
      await axiosCore.delete(`/relationships/startup/${relId}/`);
      commit('setForceFetchStartup', true);
      await dispatch('getStartupTeam', startupId);
      commit('setCurrentStartupId', startupId);
      return successAlert('Member deleted successfully!');
    } catch (error) {
      return errorAlert('Failed to delete member!', error);
    };
  },
  /**
   * Get the KYC for a startup.
   * @param {Object} context - Implicit parameter for vuex actions.
   * @param {number} startupId - The ID of the startup.
  */
  async getStartupKYC({ commit }, startupId) {
    try {
      if (isEmpty(state.startupData[startupId]?.kyc) || state.forceFetchStartup) {
        /** @type {{data: KYC}} */
        const response = await axiosCore.get(`startups/${startupId}/kyc`);
        commit('setStartupData', { startupId, startupData: { kyc: response.data } });
        commit('setForceFetchStartup', false);
      }
      commit('setCurrentStartupId', startupId);
      return state.startupData[startupId].kyc;
    } catch (error) {
      errorAlert('The Startup KYC could not be fetched!', error);
    };
  },
};

const mutations = {
  /**
   * The startup id to associate with the getters.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} startupId - ID of the startup.
   */
  setCurrentStartupId: (state, startupId) => state.currentStartupId = +startupId,
  /**
   * Updates the startup data using supplied fields for the given startup ID.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {number} startupId - ID of the startup.
   * @param {Startup} startupData - Data to set for the startup.
  */
  setStartupData: (state, { startupId, startupData }) => {
    state.startupData[startupId] = { ...state.startupData[startupId], ...startupData };
  },
  /**
   * Flag which defines if cached data should be refetched or not.
   * @param {State} state - Implicit parameter for vuex mutations.
   * @param {boolean} fetch - Whether to force fetch or not.
  */
  setForceFetchStartup: (state, fetch) => {
    state.forceFetchStartup = fetch;
  },
};

/** @typedef {typeof getters} Getters */
/** @typedef {typeof actions} Actions */
/** @typedef {typeof mutations} Mutations */

/**
 * @module startup
 * @typedef {Object} StartupStore
 * @property {State} state
 * @property {Getters} getters
 * @property {Actions} actions
 * @property {Mutations} mutations
 */

export default {
  state,
  getters,
  actions,
  mutations,
};
