import { axiosCore } from '@/plugins/axios';

const state = {
  isPortfolioLoading: false,

  /** Investment KPI indexed by offer option
   *
   * KPI example: {
   *  "Club deal €": [{ name: "Jellysmack", committed_amount: 30_000, ... }]
   *  "Rhapsody II": [{ name: "Jellysmack", committed_amount: 30_000, ... }]
   * }
   */
  kpis: {},

  /** Investment KPI in funds
   *
   * Example: [{ name: "Rhapsody II", committed_amount: 100_000, ... }]
   */
  ownKpisFunds: [],
};

const getters = {
  getKPIs: (state) => state.kpis,
  getownKpisFunds: (state) => state.ownKpisFunds,
  getIsPortfolioLoading: (state) => state.isPortfolioLoading,
};

const mutations = {
  setKPIs: (state, { name, data }) => { state.kpis[name] = data; },
  setownKpisFunds: (state, data) => { state.ownKpisFunds = state.ownKpisFunds.concat(data); },
  resetKPIs: (state, _) => { state.ownKpisFunds = []; state.kpis = {}; },
  setIsPortfolioLoading: (state, data) => { state.isPortfolioLoading = data; },
};

const actions = {
  async fetchKPIs({ commit, dispatch, getters }, investorId) {
    commit('resetKPIs');
    commit('setIsPortfolioLoading', true);
    investorId = investorId || getters.portfolioInvestorId;
    try {
      const response = await axiosCore.get(`events/investor/${investorId}/kpis?currency=EUR`);
      const data = response.data?.filter((a) => !a.is_fund);
      if (data?.length) {
        commit('setKPIs', { name: 'Club deal (in €)', data });
      }

      const response_usd = await axiosCore.get(`events/investor/${investorId}/kpis?currency=USD`);
      const data_usd = response_usd.data?.filter((a) => !a.is_fund);
      if (data_usd?.length) {
        commit('setKPIs', { name: 'Club deal (in $)', data: data_usd });
      }

      const funds = response.data?.filter((kpi) => kpi.fund_investor_id);
      for (const fund in funds) {
        const response_funds = await axiosCore.get(`events/investor/${funds[fund].fund_investor_id}/kpis`);
        commit('setKPIs', { name: funds[fund].name, data: response_funds.data });
        commit('setownKpisFunds', funds[fund]);
      }
    } catch (error) {
      dispatch('dangerAlert', error.message);
    } finally {
      commit('setIsPortfolioLoading', false);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
